.slick-dots {
  display: flex !important;
  bottom: -25px;
}

.slick-dots li.slick-active button:before {
  /* opacity: .75; */
  opacity: 1;
  color: green;
  border-radius: 100%;
}

.slick-dots li button:before {
  /* opacity: .25; */
  opacity: 1;
  color: white;
  border-radius: 100%;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 12px;
}
