.sidebar-menu:hover h3,
.sidebar-menu.seleted h3 {
  color: var(--green);
}

.sidebar-menu .chevron {
  filter: invert(1);
}

.sidebar-menu:hover .chevron,
.sidebar-menu.seleted .chevron {
  filter: invert(51%) sepia(74%) saturate(6196%) hue-rotate(153deg)
    brightness(94%) contrast(91%);
}

.sidebar-menu:hover img,
.sidebar-menu.seleted img {
  filter: invert(51%) sepia(74%) saturate(6196%) hue-rotate(153deg)
    brightness(94%) contrast(91%);
}

.sidebar-hover-box {
  display: none;
  position: absolute;
  left: 0;
  background-color: white;
  border-radius: 3px;
}

.sidebar-menu:hover .sidebar-hover-box,
.sidebar-menu.seleted .sidebar-hover-box {
  /* display: block; */
}

.children .sidebar-menu {
  height: 28px;
  border-radius: 8px;
}

.children .sidebar-menu:hover,
.children .sidebar-menu.seleted {
  background-color: var(--green);
}

.children .sidebar-menu:hover h3,
.children .sidebar-menu.seleted h3 {
  color: white;
}

.logo.small {
  margin-top: 15%;
}

.sidebar-avatar .MuiAvatar-root {
  border: 2px solid transparent;
}

.sidebar-avatar:hover h3 {
  color: var(--green);
}

.sidebar-avatar:hover .MuiAvatar-root {
  border: 2px solid var(--green);
}
