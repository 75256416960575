.swal2-confirm {
  background-color: var(--dark);
  color: var(--white);
  font-size: 16px !important;
  min-width: 64px !important;
  width: 100% !important;
  box-shadow: 0 0 0 0 var(--dark) !important;
}

.swal2-cancel {
  background-color: var(--red) !important;
  box-shadow: 0 0 0 0px var(--red) !important;
  color: var(--white);
  font-size: 16px !important;
  min-width: 64px !important;
  width: 100% !important;
}

.swal2-modal {
  z-index: 3000 !important;
}

.swal2-container {
  z-index: 2000 !important;
}

.swal2-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 0em 1.6em 0em;
}

.swal2-close {
  color: var(--dark);
}

h1 {
  font-size: "20px" !important;
  font-weight: "bold" !important;
}
h2 {
  font-size: "18px" !important;
  font-weight: 600 !important;
}
h3 {
  font-size: "16px" !important;
  line-height: "24px" !important;
  font-weight: 500 !important;
}
h4 {
  font-size: "14px" !important;
  font-weight: 500 !important;
}
